import { roundDecimals } from '@/addons/adjustments'
import { parseHTML } from '@/addons/functions'
import i18n from '@/addons/i18n'
import store from '@/store'
import { NumberFormat } from '@intlify/core-base'

/**
 * Returns a formatted and rounded currency value based on value passed
 * @param value string or number
 * @param showCurrencySymbol boolean to show currency symbol or not. Default is true (show)
 * @param noRounding whether to round or not
 * @returns string
 */
export function formatCurrency(
  value: string | number = 0,
  showCurrencySymbol = true,
  noRounding = false
): string {
  const numberValue = getParsedNumber(value)
  return formatCustomSeparators(
    noRounding ? numberValue : roundDecimals(numberValue),
    showCurrencySymbol
  )
}

/**
 * Returns a formatted currency value (without rounding - only for payments)
 * @param value string or number
 */
export function formatCurrencyWithoutRounding(value: string | number) {
  const numberValue = getParsedNumber(value)

  return formatCustomSeparators(numberValue)
}

export function getEmptyPrice(): string {
  // workaround to parse currency HTML code
  return parseHTML(`${store.state.configs.currentStore?.CURRENCY_SIGN} -`)
}

function formatCustomSeparators(
  toFormat: number,
  showCurrencySymbol = true
): string {
  const formatter = new Intl.NumberFormat(
    'en-US',
    (i18n.global.getNumberFormat('en') as NumberFormat).currency
  )
  const thousandSep = store?.state?.configs?.currentStore?.THOUSAND_SEPARATOR
  const decimalSep = store?.state?.configs?.currentStore?.DECIMAL_SEPARATOR

  const parts = formatter.formatToParts(toFormat)
  return parts.reduce((p: string, c) => {
    switch (c.type) {
      case 'decimal':
        return p + decimalSep
      case 'group':
        return p + thousandSep
      case 'currency':
        return showCurrencySymbol ? p + c.value : p
      default:
        return p + c.value
    }
  }, '')
}

/**
 * Parses a string representing a price to a number.
 *
 * This function replaces commas with dots and converts the resulting string to a float.
 *
 * @param {string | number} value - The value to be parsed. It can be a string or a number.
 * @returns {number} - The parsed number. Returns 0 if the input value is falsy.
 */
export function getParsedNumber(value: string | number) {
  if (!value) {
    return 0
  }
  // TODO: THIS LOGIC IS USELESS, WE SHOULD REMOVE IT
  if (typeof value === 'string') {
    let valueWithoutComma = value.replace(/,/g, '.')
    let digitsAfterPoint = 0
    // If the original value has a point (.) as thousands separator, we remove it
    if (value.includes('.')) {
      digitsAfterPoint = value.toString().split('.')[1].split(',')[0].length
      if (digitsAfterPoint === 3) {
        // value is a number with point (.) as thousands separator -> we remove the separator
        valueWithoutComma = value.replace(/\./g, '').replace(/,/g, '.')
      }
    }
    return parseFloat(valueWithoutComma)
  }
  return value
}
